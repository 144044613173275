/*
 * Copyright 2019 Red Hat, Inc. and/or its affiliates.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { TimeBucket } from 'domain/TimeBucket';
import DomainObjectView from 'domain/DomainObjectView';
import { ActionFactory } from '../types';
import {
  ActionType, SetTimeBucketListLoadingAction, AddTimeBucketAction,
  UpdateTimeBucketAction, RemoveTimeBucketAction, RefreshTimeBucketListAction,
} from './types';

export const setIsTimeBucketListLoading: ActionFactory<boolean, SetTimeBucketListLoadingAction> = isLoading => ({
  type: ActionType.SET_TIME_BUCKET_LIST_LOADING,
  isLoading,
});

export const addTimeBucket:
ActionFactory<DomainObjectView<TimeBucket>, AddTimeBucketAction> = timeBucket => ({
  type: ActionType.ADD_TIME_BUCKET,
  timeBucket,
});

export const removeTimeBucket: ActionFactory<DomainObjectView<TimeBucket>,
RemoveTimeBucketAction> = timeBucket => ({
  type: ActionType.REMOVE_TIME_BUCKET,
  timeBucket,
});

export const updateTimeBucket: ActionFactory<DomainObjectView<TimeBucket>,
UpdateTimeBucketAction> = timeBucket => ({
  type: ActionType.UPDATE_TIME_BUCKET,
  timeBucket,
});

export const refreshTimeBucketList: ActionFactory<DomainObjectView<TimeBucket>[],
RefreshTimeBucketListAction> = timeBucketList => ({
  type: ActionType.REFRESH_TIME_BUCKET_LIST,
  timeBucketList,
});
