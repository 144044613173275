/*
 * Copyright 2019 Red Hat, Inc. and/or its affiliates.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { Contract } from 'domain/Contract';
import { Map } from 'immutable';
import DomainObjectView from 'domain/DomainObjectView';
import { AppState } from '../types';

export const getContractById = (state: AppState, id: number): Contract => {
  if (state.contractList.isLoading) {
    throw Error('Contract list is loading');
  }
  return state.contractList.contractMapById.get(id) as Contract;
};

let oldContractMapById: Map<number, DomainObjectView<Contract>> | null = null;
let contractListForOldContractMapById: Contract[] | null = null;

export const getContractList = (state: AppState): Contract[] => {
  if (state.contractList.isLoading) {
    return [];
  }
  if (oldContractMapById === state.contractList.contractMapById && contractListForOldContractMapById !== null) {
    return contractListForOldContractMapById;
  }
  const out = state.contractList.contractMapById.keySeq().map(id => getContractById(state, id))
    .sortBy(contract => contract.name).toList();

  oldContractMapById = state.contractList.contractMapById;
  contractListForOldContractMapById = out.toArray();

  return contractListForOldContractMapById;
};
